<script>
    import { onMount } from 'svelte';
    
    let loanAmount = 150000;
    let loanAmountFormatted = formatNumberWithCommas(loanAmount); // Initialize with formatted value
    let term = 25;
    let currentInterest = current_interest_rate;
    let newInterest = new_interest_rate;
    let estimatedCurrentPayment = 0;
    let estimatedNewPayment = 0;
    let paymentDifference = 0;

    onMount(() => {
        updateCalculations();
    });

    function calculateMonthlyPayment(loanAmount, term, interestRate) {
        var months = term * 12;
        var monthlyInterestRate = interestRate / (12 * 100);
        var monthlyPayment = loanAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -months));
        return Math.round(monthlyPayment);
    }

    function handleLoanAmountInput(event) {
        const inputString = event.target.value;
        const inputValue = parseFloat(inputString.replace(/,/g, '')); // Remove commas for calculation
        if (!isNaN(inputValue)) {
            loanAmount = inputValue;
            loanAmountFormatted = formatNumberWithCommas(inputValue); // Update the formatted value
            updateCalculations();
        }
    }

	// Function to format numbers with thousands commas
    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function updateCalculations() {
        // Calculate the current and new monthly payments
        estimatedCurrentPayment = calculateMonthlyPayment(loanAmount, term, currentInterest);
        estimatedNewPayment = calculateMonthlyPayment(loanAmount, term, newInterest);

        // Update loanAmountFormatted with the new value
        loanAmountFormatted = formatNumberWithCommas(loanAmount);

        // Calculate the difference
        paymentDifference = estimatedNewPayment - estimatedCurrentPayment;
    }
</script>

<main>
    <div class="row">
        <div class="col-md-6">
            <form>
                <div class="input-container mb-3">
                    <div class="flexer">
                        <label for="loan_amount">Loan Amount:</label>
                        <div class="input-icon-container">
                            <div class="input-icon w-50">
                                <i>£</i>
                                <input type="text" class="fake-input" bind:value={loanAmountFormatted} on:input={handleLoanAmountInput}>
                            </div>
                        </div>
                    </div>
                    <input type="range" min="50000" max="1000000" step="10000" bind:value={loanAmount} on:input={updateCalculations}>
                </div>

                <div class="input-container">
                    <div class="flexer">
                        <label for="term">Loan Term (in years):</label>
                        <input type="number" class="w-25" bind:value={term} on:input={updateCalculations} required>
                    </div>
                    <input type="range" min="0" max="40" bind:value={term} on:input={updateCalculations}>
                </div>

                <div class="row two-by-two">
                    <div class="col-md-6 col-xs-6">
                        <div class="input-container">
                            <label for="current_interest">Current Interest Rate (%):</label>
                            <input type="number" class="text-left" bind:value={currentInterest} step=".5" on:input={updateCalculations} required>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <div class="input-container">
                            <label for="new_interest">New Interest Rate (%):</label>
                            <input type="number" bind:value={newInterest} step=".5" on:input={updateCalculations} required>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 calculator-results">
            <div class="results-container">
                {#if estimatedCurrentPayment !== 0}
                    <div class="costs">
                        <div class="monthly-payments">
                            <dl>
                                <dt>Estimated Current Monthly Payment</dt>
                                <dd>£{formatNumberWithCommas(estimatedCurrentPayment)}</dd>
                            </dl>
                            <dl>
                                <dt>Estimated New Monthly Payment</dt>
                                <dd>£{formatNumberWithCommas(estimatedNewPayment)}</dd>
                            </dl>
                        </div>
                        <dl>
                            <dt>Monthly Payment Difference</dt>
                            <dd>£{formatNumberWithCommas(paymentDifference)}</dd>
                        </dl>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</main>

<style>
    main {
        text-align: center;
        padding: 30px;
        max-width: 1170px;
        margin: 0 auto;
        background-color: #E6EFF4;
        border-radius: 15px;
    }

    main .row {
        display: flex;
        align-items: center;
    }

    h1 {
        margin-bottom: 1em;
    }

    .w-25 {
        width: 25%;
        flex: 1;
    }

    .w-50 {
        width: 50%;
        flex: 1;
    }

    .input-container:not(:last-child) {
        margin-bottom: 1em;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    label, input {
        margin-bottom: 0.5em;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number], .fake-input {
        background-color: transparent;
        border: none;
        color: #0FA0E7;
        font-size: 35px;
        direction: ltr;
        text-align: right;
        border-bottom: dotted 1px #0FA0E7;
    }

    .fake-input {
        border-bottom: #0FA0E7 dotted 1px;
        /* max-width: 200px; */
    }

    input[type=number]:focus, .fake-input:focus {
        outline: none;
    }

    .input-icon {
        float: right;
        width: auto;
    }

    .input-icon-container {
        flex: 1;
        text-align: right;
    }

    .input-icon-container i {
        vertical-align: super;
        font-size: 25px;
        color: #0FA0E7;
    }

    .flexer {
        display: flex;
        align-items: center;
        flex: 1;

    }

    .row .input-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .row.two-by-two .col-md-6:first-child {
        text-align: left;
    }

    .row.row.two-by-two .col-md-6:last-child {
        text-align: right;
    }

    .row.two-by-two .col-md-6 input {
        margin-bottom: 0;
    }

    .two-by-two .col-md-6 > .input-container .text-left {
        text-align: left;
    }

    .calculator-results .results-container {
        background-color: #044D71;
        border-radius: 15px;
        padding: 30px;
        min-height: 300px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .monthly-payments {
        display: flex;
        justify-content: space-between;
        width: 400px;
        margin: auto;
        margin-bottom: 1em;
    }

    dl {
        margin-bottom: 0;
    }

    dl dt {
        font-size: 14px;
        font-weight: 700;
        width: 200px;
        margin: auto;
    }

    dl dd {
        font-size: 45px;
        font-weight: 700;
    }

    /* Mobile */
    @media (max-width: 1000px) {
        main .row {
            display: block;
        }

        .monthly-payments {
            flex-direction: column;
        }

        .results-container {
            margin-top: 20px;
        } 

        .results-container dt {
            font-size: 13px;
            max-width: 150px;
        }

        .results-container dl:not(:last-child) {
            margin-bottom: 1em;
        }

        .results-container dd {
            font-size: 38px;
        }

        .monthly-payments {
            width: auto;
        }

        .monthly-payments dl dt {
            width: auto;
        }

        .flexer .input-icon-container {
            overflow: auto;
        }
    }
</style>
